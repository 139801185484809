@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css');

* {
    margin: 0;
    padding: 0;
}

html {
    /* scroll-behavior: smooth; */
    overflow-y:scroll;
    font-size: 16px;
}

:root {
    --color1: #4E4FEB;
    --color2: #9D91C6;
    --color3: #F5EBFF;
    --color4: #69BAA5;
    --color5: #0089FE;
    --color6: #FF424A;
    --color7: #01011c;
    --color8: #f3f1f5;
    --color-main: #4E4FEB !important;
}

body {
    /* overflow-y: scroll; Add a vertical scrollbar to the entire body */
    scrollbar-color: var(--color5) var(--color8); /* Customize scrollbar color and track color */
}

/* You can further customize the scrollbar appearance if needed */
body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-thumb {
    background: linear-gradient(135deg, var(--color5), var(--color6));
    border-radius: 24px;
}

body::-webkit-scrollbar-track {
    background-color: var(--color3);
}   

/* changing the color scheme */

#root .text-primary{
    color: var(--color-main) !important;
}

body .text-primary {
    color: var(--color-main) !important;
}

#root .btn-primary{
    background: var(--color-main) !important;
}

body .btn-primary{
    background: var(--color-main) !important;
}

#root .btn-primary{
    background: var(--color-main) !important;
}

body .btn-primary{
    background: var(--color-main) !important;
}

#root .btn-outline-primary:hover{
    background: var(--color-main) !important;
}

body .btn-outline-primary:hover{
    background: var(--color-main) !important;
}

#root .bg-primary{
    background: var(--color-main) !important;
}

body .bg-primary{
    background: var(--color-main) !important;
}

#root .border-primary{
    border-color: var(--color-main) !important;
    color: var(--color-main) !important;
    background-color: var(--color-main) !important;
}

body .border-primary{
    border-color: var(--color-main) !important;
    color: var(--color-main) !important;
    background-color: var(--color-main) !important;
}

/* hide input type  number arrow */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
