/* admin login */

.admin-login {
    width: 100%;
    height: 100vh;
    background-color: black !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-login-main-container {
    width: 500px;
    background-color: gray;
    padding: 5% 2%;
}

.dashboard-sidebar {
    height: 100vh;
}


.blog-editor {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.blog-input {
    /* height: 500px; */
}

.blog-editor form {
    display: flex;
    flex-direction: column;
}

.input-title,
.input-thumbnail {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
}

.submit-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}