/* sidebar contact form starts */

.sidebar-contact {
    position: fixed;
    top: 35%;
    right: -30%;
    /* transform: translateY(-0%); */
    width: 30%;
    height: 450px;
    padding: 40px;
    background: var(--bs-dark);
    /* box-shadow: 0 20px 50px rgba(0, 0, 0, .5); */
    box-sizing: border-box;
    transition: 0.5s;
    z-index: 999;
}

.sidebar-contact.active {
    right: 0;
}

.sidebar-contact input,
.sidebar-contact textarea {
    width: 100%;
    height: 36px;
    padding: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, .5);
    outline: none;
}

.sidebar-contact h2 {
    margin: 0 0 20px;
    padding: 0;
}

.sidebar-contact textarea {
    height: 60px;
    resize: none;
}

.sidebar-contact input[type="submit"] {
    background: #4E4FEB;
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 18px;
}

.toggle {
    position: absolute;
    height: 48px;
    /* width: 48px; */
    padding: 0 1em;
    font-weight: bold;
    color: white;
    text-align: center;
    cursor: pointer;
    background: #f00;
    top: 0;
    /* right: -48px; */
    left: -100px;
    line-height: 48px;
    transform: rotate(270deg);
}

.toggle:before {
    content: '\f003';
    font-family: fontAwesome;
    font-size: 18px;
    color: #fff;
    margin-right: 1em;
}

.toggle.active:before {
    content: '\f00d';
}

@media screen and (max-width:768px) {
    .sidebar-contact {
        top: 35%;
        width: 100%;
        height: 100%;
        right: -100%;
    }

    .sidebar-contact .toggle {
        /* top: 50%; */
        /* bottom: 50%; */
        /* margin: auto; */
        /* transform: translateY(-50%); */
        transition: 0.5s;
        transform: rotate(270deg);
    }

    .sidebar-contact.active .toggle {
        top: 0;
        right: 0;
        transform: translateY(0);
    }

    .scroll {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .content {
        padding: 50px 50px;
    }
}

@media screen and (max-width:576px) {
    .sidebar-contact .toggle {
        /* top: 50%; */
        /* bottom: 50%; */
        /* margin: auto; */
        /* left: 0; */
        transform: translateY(-50%);
        transition: 0.5s;
        /* transform: rotate(270deg); */
    }

    .toggle {
        left: -45px;
    }

    .sidebar-contact.active .toggle {
        top: -1em;
    }

}


/* sidebar contact form ends */

/* social media sidebar starts */

/* social media sidebar for mobile and desktop and tablet */

/* special whatsapp */

.social-media-target-button {
    content: '';
    /* background-color: var(--bs-danger); */
    padding: 0 0.5rem;
    color: var(--bs-danger);
    font-weight: 600;
    cursor: pointer;
    filter: drop-shadow(0 0 0.05rem black);
}

.icon-bar:hover .social-media-bar-content {
    position: relative;
    left: 0;
}

#whatsapp-mobile:hover {
    font-size: 2em;
}

.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%) translateX(-70%);
    z-index: 9999999;
    transition: all 0.3s ease;
}

.icon-bar:hover {
    transform: translateY(-50%) translateX(0);
}

.icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
}

.icon-bar a:hover {
    background-color: #000;
}

.facebook {
    background: #3B5998;
    color: white;
}

.twitter {
    background: #55ACEE;
    color: white;
}

.google {
    background: var(--bs-danger);
    color: white;
}

.linkedin {
    background: #007bb5;
    color: white;
}

.whatsapp {
    background: #04AA6D;
    color: white;
}

/* social media sidebar ends */

/* sub navbar */
.navbar-main-1 {
    background-color: var(--color1);
    color: var(--color3);
    padding: 5px 1%;
    font-weight: 500;
}

/*  */

.navbar-main-2 {
    padding: 5px 5% !important;
    background-color: var(--color7);
    position: sticky !important;
    top: 0;
    z-index: 9999;
    transition: all ease 0.5s;
}

.navbar-main-2 .navbar {
    padding: 0;
}

/* reduced navbar css */

.reduced-navbar {
    padding: 0;
    transition: all ease 0.5s;
}

.org-logo-width {
    width: 200px;
    transition: all ease 0.5s;
}

.scrolled-logo-width {
    width: 130px;
    transition: all ease 0.5s;
}

.navbar-reduced-height {
    max-height: 70px;
    transition: all ease 3s;
    position: sticky;
    top: 0;
}

.navbar-reduced-height>* {
    padding: 0;
}

a.nav-link {
    color: var(--color3) !important;
    position: relative;
    z-index: 1;
}

/* .dropdown-menu a.nav-link{
    color: var(--color1);
} */
/* 
.nav-link:link{
    color: black !important;
}

.nav-link:visited{
    color: black !important;
} */

/* .navbar-toggler-icon {
    background-color: white;
} */

a.nav-link::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(135deg, var(--color5), var(--color6));
    transition: all 0.5s ease;
}

a.nav-link:hover::before {
    width: 100%;
}

.nav-link:hover {
    color: var(--color1) !important;
    transform: rotate(360deg);
}

.transition-dropdown {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.transition-dropdown.show {
    max-height: 500px;
    /* Set an appropriate max-height value */
}

.dropdown-menu a.nav-link {
    color: #03003B !important;
}

/* mega menu */

/* Add this CSS to your existing styles or create a new stylesheet */

.dropdown-menu {
    width: 60% !important;
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%);
    border-radius: 24px !important;
    border: 0;
    padding: 1.5rem !important;
    outline: none;
    border: none;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
}

.navbar-dropdown-img {
    width: 300px !important;
    height: 290px !important;
}

/* .dropdown-menu ul li:first-child,
.dropdown-menu ul li:first-child a {
    font-weight: bold;
    font-size: 1.2rem;
    color: red !important;
} */

/* media query */

.navbar-toggler {
    border: 0 !important;
    outline: 0 !important;
}

@media screen and (min-width : 993px) {

    /* .dropdown:hover .dropdown-menu {
        display: flex;
    } */

    .dropdown-menu.show {
        display: flex !important;
        width: 100%;
        justify-content: space-evenly;
        transition: all 1s ease;
    }
}

@media screen and (max-width : 576px) {
    .dropdown-menu.show {
        position: relative !important;
        width: 100% !important;
        max-height: 60vh;
        overflow-y: scroll;
        text-align: start;
    }

    .navbar-brand {
        width: 50%;
    }
}

/* saearch input filed */

/* .navbar-search-input{
    border: none;
    outline: none;
} */

.search-navbar {
    /* border: 2px solid #0B0159; */
    padding: 10px;
    border-radius: 25px;
}

/* menu item  */
@media screen and (max-width:576px) {
    .menu-items-custom {
        color: red;
    }

    .nav-link {
        color: red !important;
    }

    .cus-items .nav-link {
        color: var(--color-main) !important;
    }

    .cus-items.footer-quick-link .nav-link {
        color: white !important;
    }

}

/* .navbar-cta-button{
    outline: none;
    border: none;
    padding: 0.5em 3em;
    font-size: 1.2em;
    border-radius: 45px;
} */

.button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    /* background-color: rgb(0 107 179); */
    background: linear-gradient(135deg, var(--color5), var(--color6));
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
}

.reducced-button {
    padding-block: 0.2rem;
    padding-inline: 0.5rem;
    font-size: 10px;
}

.icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    transform: scale(1.05);
    border-color: #fff9;
}

.button:hover .icon {
    transform: translate(4px);
}

.button:hover::before {
    animation: shine 1s ease-out infinite;
}

.button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}


/* heros section */


#earth {
    position: fixed;
    width: 100%;
    height: 100%;
    touch-action: manipulation;
}

.heros-section {
    padding: 0 5%;
    /* background: linear-gradient(135deg, var(--color1) 50%, var(--color8) 50%); */
    /* background: radial-gradient(var(--color1) 50%,var(--color5)); */

}

.heros-welcome {
    color: var(--color7);
}

.typing-title {
    font-size: 2em !important;
    background: linear-gradient(135deg, var(--color5), var(--color6));
    /* background: url("../media/backtext.jpg"); */
    /* background-position: center center; */
    /* background-size: cover; */
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-shadow: 0 0 1px white;  */
    /* text-shadow: 0 0  white; */
    /* background-color: red; */
}

@media screen and (max-width:576px) {
    .typing-title {
        font-size: 1.7em !important;
    }

}

.Tagline-text {
    background: linear-gradient(135deg, var(--color5), var(--color6));
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* font-family: 'Dancing Script', cursive; */
}

/* heros button */
.heros-cta {
    outline: none;
    border: none;
    background-color: var(--color7);
    display: inline-block;
    color: white;
    padding: 0.5em 1em;
    gap: 1em;
    border-radius: 25px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.heros-cta-btn {
    background: linear-gradient(135deg, var(--color5), var(--color6));
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin: 0 0.2em;
    font-size: 1.2em;
    font-weight: bolder;
    border: none;
    outline: none;
}

.heros-cta::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    width: 1px;
    height: 1px;
    background: white;
    border-radius: 100%;
    transform: scale(0);
    transition: all 1s ease;
}

.heros-cta:hover::before {
    transform: scale(1000);
    /* color: black; */
}

.heros-cta:hover {
    color: black;
}

.heros-container {
    transform: scale(0.8);
}

@media screen and (max-width:576px) {
    .heros-cta {
        padding: 0.2em 0.5em;
    }
}


.perks-section {
    background-color: var(--color7);
    width: 100%;
}

.perks-content {
    padding: 1em;
    box-shadow: 0 0 2px white;
    border-radius: 15px;
}

.heros-all-container {
    z-index: 0 !important;
}


/* About Section */

.about-image-img {
    transform: scale(0.8);
    transition: all ease 0.4s;
}

.about-image-img:hover {
    transform: scale(1);
}

.about-img-circle {
    transform: scale(1) !important;
    transition: all ease 0.4s;
}

.about-image-img:hover+.about-img-circle {
    /* width: 25px !important;
    height: 25px !important; */
    transform: scale(0.8) !important;
    transition: all ease 0.4s;
}

@media screen and (max-width: 576px) {

    .about-image-img {
        transform: scale(1);
    }

    .about-image-img:hover {
        transform: scale(1.3);
    }

    .about-img-circle {
        width: 35px !important;
        height: 35px !important;
    }
}

.about-list {
    list-style-type: none;
    padding: 0;
}

.about-list li {
    font-weight: 500;
}

.about-li::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f560";
    padding: 1%;
    color: var(--color1);
}

/* heros section floting disc type animation */

.heros-img-section-animation {
    position: relative;
    animation: up-down-ani1;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

/* .heros-img{

} */


.disc-group {
    width: 100%;
    /* background-color: black; */
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.disc-1 {
    background-color: var(--color1);
    /* animation: up-down-ani1;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    z-index: 1; */
}

.disc-2 {
    background-color: var(--color2);
    /* animation: up-down-ani1;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite; */
}

.disc-1,
.disc-2 {
    width: 65%;
    height: 50%;
    border-radius: 100%;
    outline: 5px dashed black;
    transform: rotateX(35deg);
    /* transform: rotate(); */
}

@keyframes up-down-ani1 {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes rotrating-disc-animation {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

/* about_section */

/* .about_section{
    background-color: var(--color2);
} */

/* heros-ending-strip */

.heros-ending-strip {
    height: 5vh;
    overflow: hidden;
}

.srtip-band-grp {
    height: 100%;
    position: relative;
}

.strip-1,
.strip-2,
.strip-3,
.strip-4 {
    height: 100%;
    width: 30%;
    /* background-color: red; */
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    text-align: end;
    align-items: center;
    padding: 0 1%;
}

@media screen and (max-width: 756px) {

    .strip-1 {
        background-color: var(--color1);
        left: 0;
        z-index: 4;
    }

    .strip-2 {
        background-color: var(--color5);
        width: 33%;
        left: 10%;
        z-index: 3;
    }

    .strip-3 {
        background-color: #f00;
        width: 33%;
        left: 20%;
        z-index: 2;
    }

    .strip-4 {
        /* background-color: yellow; */
        display: none;
        left: 40%;
        z-index: 1;
    }

}

.strip-1 {
    background-color: var(--color1);
    left: 0;
    z-index: 4;
}

.strip-2 {
    background-color: var(--color5);
    left: 10%;
    z-index: 3;
}

.strip-3 {
    background-color: #f00;
    left: 22%;
    z-index: 2;
}

.strip-4 {
    /* background-color: yellow; */
    left: 46%;
    z-index: 1;
}

/* about-accordion */

.about-accordion {
    background-color: rgba(78, 79, 235, 0.1);
}

.accordion-item-about {
    margin: 1.4em 0;
}


/* new section solution for  */

.new-img-content {
    aspect-ratio: 0.8 / 0.5;
    object-fit: cover;
}

.new-img-content2 {
    position: absolute;
    left: 50%;
    top: 50%;
    aspect-ratio: 1 / 0.5;
    width: 75%;
    object-fit: cover;
}

@media screen and (max-width:990px) {

    .new-img-content2 {
        position: relative;
        left: 0;
        top: 0;
        aspect-ratio: 1 / 0.5;
        width: 75%;
        object-fit: cover;
    }

}

/* about-li */

.about-ul-li-new {
    list-style: none;
}

.about-li-new {
    text-align: end;
}

.about-li-new::after {
    content: '\f013';
    font-family: fontAwesome;
    font-size: 18px;
    color: var(--color1);
    position: relative;
    left: 0.25em;
}

@media screen and (max-width:576px) {
    .about-ul-li-new {
        list-style: none;
    }

    .about-li-new {
        text-align: start;
    }

    .about-li-new::after {
        content: "";
    }

    .about-li-new::before {
        content: '\f013';
        font-family: fontAwesome;
        font-size: 18px;
        color: var(--color1);
        position: relative;
        /* left: .25em; */
    }
}

/* solution-for-section-card */

.service-card-container-bg {
    background: rgba(33, 37, 41, 0.95);
}


.img-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 0.75rem;
    column-gap: 0.75rem;
}

/* .grid-images img{
    width: 100%;
    height: 100%;
} */

.grid-image-1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.grid-images:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.solution-for-section-card {
    background-color: rgba(255, 255, 255, 1);
}

.core-service-grid .card {
    transition: all 0.67s ease;
}

.core-service-grid .card:hover {
    background: var(--bs-light) !important;
}

.core-service-grid .card:hover .service-init-price {
    color: var(--bs-dark) !important;
}

.core-service-grid .card-title {
    transition: all 0.67s ease;
}

.core-service-grid .card:hover .card-title {
    color: var(--bs-dark) !important;
}

.service-badges .badge {
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all 0.3s ease;
}

.service-badges .badge::before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: var(--color-main);
    transition: all 0.3s ease;
}

.service-badges .badge:hover::before {
    width: 100%;
}

.service-badges .badge:hover {
    color: var(--bs-light) !important;
}



/* .service-item{
    background-color: red;
} */

.service-item .details {
    position: relative;
    /* top: -50px; */
    padding: 1em 0;
}

.content-only {
    /* background-color: rgba(78,79,235,0.8); */
    background-color: rgba(225, 225, 225, 1);
    border-radius: 5px;
    border: 0.2em solid white;
    padding: 1.5em 1em;
    position: relative;
    z-index: 9;
    /* position: relative; */
    overflow: hidden;
}

.content-only::after {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background-color: var(--color-main);
    top: -50%;
    /* left: 0; */
    right: 0;
    z-index: -1;
    border-radius: 50%;
    transform: rotate(-45deg) scale(2);
    transition: all 0.5s ease;
}

.content-only:hover::after {
    width: 100%;
}

.content-only:hover h4 {
    color: white !important;
    /* transition: all 1.3s ease; */
}

.content-only:hover h4 span {
    color: var(--bs-warning) !important;
    /* transition: all 1.3s ease; */
}

.content-only:hover ul li {
    color: white;
}

.content-only:hover ul li .fa-solid {
    color: var(--bs-warning) !important;
    transition: all 1.3s ease;
}

.details-content .icon-container {
    background-color: white;
    display: inline-block;
    border-radius: 50%;
    border: 3px solid var(--color-main);
    position: relative;
    width: 50px;
    height: 50px;
}

.details-content .icon-container .icon-icon::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
}

.make-it-top-icon {
    position: absolute;
    right: 0;
    top: -5%;
    z-index: 100;
    /* left: 50%; */
    /* transform: translateX(-50%); */
}

/* tech stack image in revolving pattern */

.tech_stack_img {
    width: 100px;
    aspect-ratio: 1 / 1;
}

.tech-stack-back {
    background: url("../media/tech-logo/technology-back.png");
    background-size: cover;
    background-position: center center;
}

.tech_stack_main {
    width: 100%;
    height: 80vh;
    border-color: #EEE;
    border-radius: 10px;
    border-width: 2px;

    display: grid;
    justify-content: center;
    align-content: center;
}

.tech_stack_main>* {
    grid-column: 1;
    grid-row: 1;
}

.tech_item {
    animation: spinAround 22.5s linear infinite;
    /* -webkit-animation: spinAround 15s linear infinite; */
}

.tech_stack_main>*>.tech_stack_img {
    /* transform: rotate(45deg); */
    animation: rotate 22.5s linear infinite;
}

/* note time of item and img are important for this kind og animation  */
/* also they must have same delay */
@keyframes rotate {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(-1turn);
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0turn) translate(250px) scale(.8);
    }

    to {
        transform: rotate(1turn) translate(250px) scale(.8);
    }
}

@media screen and (max-width:576px) {

    .tech_stack_img {
        width: 50px;
    }

    .tech_stack_main {
        width: 100%;
        height: 50vh;
    }

    @keyframes spinAround {
        from {
            transform: rotate(0turn) translate(145px) scale(.8);
        }

        to {
            transform: rotate(1turn) translate(145px) scale(.8);
        }
    }
}

/* overlapping center img and icons */
.tech-stack-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
}

.icons-revolving-around,
.centred-img {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.centred-img {
    position: relative;
}

.centred-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.chess-icons {
    width: 50px;
    height: 50px;
    background-color: var(--color-main);
    border-radius: 100%;
    position: relative;
    transition: all 0.5s ease;
}

.chess-icons .fa-solid {
    position: absolute;
    left: 50%;
    top: 50%;
    color: var(--bs-light);
    transform: translate(-50%, -50%);
}

.chess-icons:hover {
    background-color: white;
    border: 2px solid var(--color-main);
}

.chess-icons:hover .fa-solid {
    color: var(--color-main);
}

/* project card  */
.spin {
    /* display: flex;
    justify-content: start;
    align-items: center; */
    min-height: 100%;
    overflow: hidden;
}

.circle {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    left: 50%;
    z-index: 999; */
}

.Logo_logo {
    position: absolute;
    width: 150px;
    height: 150px;
    background: url("../media/websync.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    /* border-radius: 50%; */
    /* background-color: red; */
}

.Stext {
    position: static;
    width: 100%;
    height: 100%;
    text-align: center;
    animation: rotateText 10s linear infinite;
}

.Stext span {
    position: absolute;
    /* left: 50%; */
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bolder;
    font-size: 1.4rem;
    transform-origin: 0 150px;
}

@keyframes rotateText {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

/* FAQ */

.FAQ {
    background: white;
}

/* footer */

.footer {
    background-color: black;
}

.footer-social-list .list-group,
.footer-social-list .list-group-item {
    border: none;
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.5);
    /* opacity: 0.6; */
    cursor: pointer;
}

.footer-social-list .list-group-item:hover {
    color: rgba(78, 79, 235, 1);
    opacity: 1;
}

/* footer */
.footer-map {
    min-height: 350px !important;
    outline: none;
}

/* service page */

.service-page-main {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)) , url('../media/service-page/comman-banner.jpg');
    background-position: center center;
    background-size: cover;
    /* background-attachment: fixed; */
    width: 100%;
    height: 50vh;
}

.service-hero-cta .btn{
    gap: 0.5rem;   
    transition: all 0.5s ease;
}

.service-hero-cta .btn:hover{
    gap: 3rem;
}

.heros-text{
    width: 50%;
}

.service-bar-links{
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .heros-text{
        width: 100%;
    }   
    .service-bar-links{
        font-size: 0.7rem;
    }
}

#exampleModal {
    z-index: 999999;
}

/* service main blogs */

.service-block-details {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../media/servicesbanners/service-demo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 35vh;
}


.service-blog-capped-images {
    width: 100%;
    /* background-color: red; */
    object-fit: cover;
    aspect-ratio: 3/1;
}

.service-blog-capped-images-1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 2;
}

.service-blog-capped-images-1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 4
}

.summary-list {

    list-style: none;
    padding: 0;

    /* content: '\f003';
    font-family: fontAwesome; */
}

.summary-list-item {
    padding: 0.5rem 0;
}

.summary-list-item::before {
    content: '\f101';
    font-family: fontAwesome;
    color: var(--bs-danger);
    font-weight: 500;
    padding: 0 5px;
}


/* blogs grid */

.blog-grid-thumbnail{
    width: 100%;
    height: 150px;
}